import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Lists are among the basic building blocks of the web. Every web developer knows the HTML
elements `}<InlineCode mdxType="InlineCode">{`<`}{`ul`}{`>`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`<`}{`ol`}{`>`}</InlineCode>{` for
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/ul"
      }}>{`unordered`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/ol"
      }}>{`ordered lists`}</a>{`.
Yet few are familiar with their close relative `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`, the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl"
      }}>{`description list`}</a>{`.`}</p>
    <p>{`A typical use case for the element is to implement a glossary with terms and definitions. In fact, you can do
a lot more with it. I would argue that any list of key-value pairs should be marked up as a description list.
It improves the `}<a parentName="p" {...{
        "href": "https://medium.com/swlh/what-is-dx-developer-experience-401a0e44a9d9"
      }}>{`developer experience`}</a>{`
by making the code more readable. And it makes your content more accessible.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAFjtM2jmkP/xAAbEAACAwADAAAAAAAAAAAAAAABEgACExEhIv/aAAgBAQABBQLVpfhRiYO6q8t5P//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAQQDAAAAAAAAAAAAAAAAATECEBGBEkFx/9oACAEBAAY/AtC47IwNx4ijq3//xAAZEAEBAQEBAQAAAAAAAAAAAAABETEAIVH/2gAIAQEAAT8hsZcdokRdb0NafK8W4IUw6I4dnVAfO//aAAwDAQACAAMAAAAQ4B//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/EHGtJg//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EAxu3//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQYFxocH/2gAIAQEAAT8QM2MgG7W4bYjBoAc+p3fAfZrroASh13Lt9iqN5y+cQ2fyB4n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Two people discussing the HTML code on the laptop monitor in front of them.",
          "title": "Two people discussing the HTML code on the laptop monitor in front of them.",
          "src": "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg",
          "srcSet": ["/static/d4e56c8e213c409c18497393ef2b4a01/f93b5/pexels-ketut-subiyanto-confused-couple.jpg 300w", "/static/d4e56c8e213c409c18497393ef2b4a01/b4294/pexels-ketut-subiyanto-confused-couple.jpg 600w", "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg 1200w", "/static/d4e56c8e213c409c18497393ef2b4a01/b17f8/pexels-ketut-subiyanto-confused-couple.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Ketut Subiyanto / pexels.com`}</em></p>
    <p>{`We'll take a look at the history of the element, see how it works and how it affects accessibility.`}</p>
    <h2>{`A Quick History of the Description List Element`}</h2>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{` element has existed since `}<a parentName="p" {...{
        "href": "https://www.w3.org/MarkUp/HTMLPlus/htmlplus_34.html"
      }}>{`HTML+`}</a>{`
in the year 1993. The document defined the element as a “definition list”:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A `}<BoldText mdxType="BoldText">{`definition list`}</BoldText>{` is a list of terms and corresponding definitions. Definition lists are
typically formatted with the term flush-left and the definition, formatted paragraph style, indented after the term.`}</p>
    </blockquote>
    <p>{`This narrow definition is still present in many minds today. But it no longer applies. The element's definition was changed
in `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/multipage/grouping-content.html#the-dl-element"
      }}>{`HTML5`}</a>{`, making it more versatile:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<BoldText mdxType="BoldText">{`dl element`}</BoldText>{` represents an association list consisting of zero or more name-value groups
(a `}<BoldText mdxType="BoldText">{`description list`}</BoldText>{`). `}{`[...]`}{` Name-value groups may be terms and definitions, metadata topics and
values, questions and answers, or any other groups of name-value data.`}</p>
    </blockquote>
    <p>{`This means that the element can be used to markup any list of key-value pairs. For example, a list of metadata about
a client: name, address, telephone number, etc.`}</p>
    <h2>{`How to use the `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{` element`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl"
      }}>{`description list`}</a>{` contains a list of terms and
descriptions that are defined with the HTML elements `}<InlineCode mdxType="InlineCode">{`<`}{`dt`}{`>`}</InlineCode>{` (description term)
and `}<InlineCode mdxType="InlineCode">{`<`}{`dd`}{`>`}</InlineCode>{` (description details). Here's an example for a list of metadata:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dl>
    <dt>Account Owner</dt>
    <dd>Martina Musterfrau</dd>
    <dt>Banking Institute</dt>
    <dd>Musterbank</dd>
    <dt>IBAN</dt>
    <dd>AT12 3456 7891 2345 6789</dd>
    <dt>Telephone</dt>
    <dd>+43 1234 56 78</dd>
</dl>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The semantic HTML tags convey important information to assistive technologies. Screen readers can navigate the
individual list items and convey the relationship between term and description. But web developers also benefit from
the semantic markup. Imagine the example above as a sequence of `}<InlineCode mdxType="InlineCode">{`<`}{`div`}{`>`}</InlineCode>{` elements:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="metadata">
    <div class="term">Account Owner</div>
    <div class="description">Martina Musterfrau</div>
    <div class="term">Banking Institute</div>
    <div class="description">Musterbank</div>
    <div class="term">IBAN</div>
    <div class="description">AT12 3456 7891 2345 6789</div>
    <div class="term">Telephone</div>
    <div class="description">+43 1234 56 78</div>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`I hope you agree with me that the semantic HTML is easier to understand and work with. You can also leverage the
semantic tag names for expressive and easy to understand CSS selectors. Check out my article
`}<a parentName="p" {...{
        "href": "https://www.oidaisdes.org/3-ways-web-devs-benefit-accessibility.en/"
      }}>{`“3 Ways how Web Developers also benefit from Accessibility”`}</a>{`.`}</p>
    <h2>{`Styling the Description List`}</h2>
    <p>{`The standard styling of description lists is rather bland. But don't worry! You can freely customize the element with
CSS. The HTML specification allows wrapping each key-value pair in a `}<InlineCode mdxType="InlineCode">{`<`}{`div`}{`>`}</InlineCode>{` element.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dl>
    <div>
        <dt>Account Owner</dt>
        <dd>Martina Musterfrau</dd>
    </div>
    <div>
        <dt>Banking Institute</dt>
        <dd>Musterbank</dd>
    </div>
    <div>
        <dt>IBAN</dt>
        <dd>AT12 3456 7891 2345 6789</dd>
    </div>
</dl>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This content structure enables us to create a responsive, horizontal layout using
`}<a parentName="p" {...{
        "href": "https://css-tricks.com/snippets/css/a-guide-to-flexbox/"
      }}>{`CSS flexbox`}</a>{`:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dl {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

dl > div {
    flex-grow: 1;
}

dt {
    color: #6e2755;
    font-size: 0.9rem;
    font-weight: bold;
}

dd {
    font-size: 1rem;
    margin-inline-start: 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Here's the end result:`}</p>
    <iframe title="Flexbox styled Description List" src="https://codepen.io/alexlehner86/embed/eYLwwaa?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/eYLwwaa">
    Flexbox styled Description List</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`Screen Reader Support`}</h2>
    <p>{`The description list element makes your content more accessible, especially for screen reader users. There are minor
differences between browsers and screen readers, but overall the support is generally good.
Adrian Roselli has done extensive screen reader testing for his article
`}<a parentName="p" {...{
        "href": "https://adrianroselli.com/2022/12/brief-note-on-description-list-support.html"
      }}>{`“Brief Note on Description List Support”`}</a>{`.
Go check it out!`}</p>
    <p>{`I'd like to add one important aspect to his analysis: Screen readers are software applications. And software can have
bugs or miss an important feature (like the correct handling of the `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{` element). But
this should not stop us from using this feature. Especially if we can expect the screen reader vendor to fix their
mistakes in the near future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      